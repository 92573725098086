import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { CustomTooltip, IBaseRight, Loader, NavBar, NavDrawer, SideMenuItem, lowercase } from '@zz2/zz2-ui';
import lodash from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { IUser } from '../../@types/model/right/user/userModel';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import EnvHelper from '../../service/helper/envHelper';
import AuthThunks from '../../store/auth/thunk';
import GeneralActions from '../../store/general/actions';
import { VERSION } from '../../version';
import Routes from './Routes';
import UserSettings from './UserSettings';
import { useGetRights } from '../../hooks/query/rights/rightsQueries';
import { RightGrantsEnum } from '../../@types/enum/rightGrantsEnum';
import UserHelper from '../../service/helper/userHelper';
import FreshServiceActions from '../../store/freshService/actions';
import FreshServiceTicketCreationDialog from '../freshService/FreshServiceTicketCreationDialog';

const iconsLocation = `${ASSET_BASE}/assets/icons`;

const NavFrame = () : React.ReactElement => {
    const dispatch = useAppDispatch();
    const path = useLocation().pathname;
    const currentUser = useAppSelector<IUser | undefined>(x => x.auth.session?.user);
    const isNavDrawerOpen = useAppSelector(x => x.general.isNavDrawerOpen);
    const freshTicketServiceDescription = useAppSelector(x => x.freshService.supportTicketDescription);
    const isFreshServiceTicketDialogOpen = useAppSelector(x => x.freshService.isSupportTicketDialogOpen);

    const [isUserSettingsDialogOpen, setIsUserSettingsDialogOpen] = useState<boolean>(false);

    /*================================================================================================================
     *                                                  Queries
     * ==============================================================================================================*/

    const { isLoading: isLoadingRights, data: rightData, refetch: loadRights } = useGetRights(false);

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        if (isNavDrawerOpen) {
            loadRights();
        }
    }, [isNavDrawerOpen]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const signOut = () : void => {
        dispatch(AuthThunks.logout());
        dispatch(GeneralActions.setNavDrawer(false));
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/
    const openDrawer = () : void => {
        dispatch(GeneralActions.setNavDrawer(true));
    };

    const closeDrawer = () : void => {
        dispatch(GeneralActions.setNavDrawer(false));
    };

    const handleFreshServiceTicketDialogOpen = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
    };

    const handleFreshServiceTicketDialogClose = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(false));
    };

    const requestAccess = (right : IBaseRight) : void => {
        const regex = new RegExp('/', 'g');
        const envTitle = ENV_NAME === 'production' ? 'E-Wages Website' : 'E-Wages QA Website';
        const title = (envTitle + '/' + VERSION.version).replace(regex, ' - ');

        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
        dispatch(FreshServiceActions.setSupportTicketTitle(title));
        dispatch(FreshServiceActions.setSupportTicketDescription(`Requesting permission: ${right.name} - View.`));
    };

    const isParentOfCurrentPage = (parentId : number, rights ?: Array<IBaseRight>, pathname ?: string) : boolean => {
        const currentPageRight = rights?.find(x => x.url === pathname);
        const parentRight = rights?.find(x => x.id === parentId);
    
        if (currentPageRight?.parentId) {
            return (currentPageRight.parentId === parentId) || (!!parentRight?.parentId && isParentOfCurrentPage(parentRight.parentId, rights, pathname));
        }
        return false;
    };

    const openUserSettings = () => {
        setIsUserSettingsDialogOpen(true);
    };

    const closeUserSettings = () => {
        setIsUserSettingsDialogOpen(false);
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const userSettings = useMemo<React.ReactElement>(() => {
        if (currentUser) {
            return <UserSettings
                isOpen={isUserSettingsDialogOpen}
                currentUser={currentUser}
                onClose={closeUserSettings}
                onLogout={signOut}
            />;
        }

        return <div></div>;
    }, [isUserSettingsDialogOpen]);

    const currentUserRights = useMemo(() => {
        return currentUser?.userRights.map(x => x.right) ?? [];
    }, []);

    const userCurrentRight = useMemo<IBaseRight | undefined>(() => {
        const urlSections = path.split('/');
        const firstPartition = urlSections.slice(0, -1).join('/');

        return currentUserRights.filter(x => x.isActive).find(
            x => x.url === (urlSections[urlSections.length - 1].includes('Id')
                ? firstPartition
                : path
            )
        );

    }, [currentUser, path]);


    const breadcrumbs = useMemo<Array<{ name : string; url : string }>>(() => {
        const breadcrumbList : Array<{ name : string; url : string }> = [];

        if (userCurrentRight) {
            let currentRight : IBaseRight | undefined = userCurrentRight;

            do {
                breadcrumbList.push({ name: currentRight.name, url: currentRight.url ?? '' });
                currentRight = currentUserRights.find(x => x.id === currentRight?.breadcrumbParentId);
            } while (currentRight);
        }
        return breadcrumbList.reverse();
    }, [currentUser, location, currentUserRights, userCurrentRight]);


    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    const renderListSection = (right : IBaseRight, children : Array<IBaseRight>, rights ?: Array<IBaseRight>, pathname ?: string) : JSX.Element => {
        const userRights = currentUser?.userRights;
        const hasViewRight = userRights?.some(x =>
            x.isActive
            && x.right.id === right.id
            && x.rightGrantLevel === RightGrantsEnum.View);

        return (
            <CustomTooltip
                title={!hasViewRight ? UserHelper.getRelatedRightToolTipMessage(right.name, RightGrantsEnum.View) : ''}
                onClick={() : void => requestAccess(right)}
                rightValidation={!hasViewRight}
                key={'section_custom_tooltip_' + right.id}>
                <SideMenuItem
                    className={'pt10'}
                    paddingLeft={0}
                    icon={<img className={'h30 w30'} src={`${iconsLocation}/${lowercase(right.name).replace(/ /g, '_')}.svg`}/> }
                    title={right.name}
                    key={'section_' + right.id}
                    boldTitle={isParentOfCurrentPage(right.id, rights, pathname)}
                    disabled={!hasViewRight}>
                    {children.map(x => renderListItem(x, false, pathname))}
                </SideMenuItem>
            </CustomTooltip>
        );
    };

    const renderListItem = (right : IBaseRight, hasIcon : boolean, pathname ?: string) : JSX.Element => {
        const userRights = currentUser?.userRights;
        const hasViewRight = userRights?.some(x =>
            x.isActive
            && x.right.id === right.id
            && x.rightGrantLevel === RightGrantsEnum.View);

        return (
            <CustomTooltip
                title={!hasViewRight ? UserHelper.getRelatedRightToolTipMessage(right.name, RightGrantsEnum.View) : ''}
                onClick={() : void => requestAccess(right)}
                rightValidation={!hasViewRight}
                key={'list_item_custom_tooltip_' + right.id}>
                <Link key={'list_item_link_' + right.id} to={right.url ?? ''} onClick={closeDrawer} style={{ pointerEvents: hasViewRight ? 'auto' : 'none', textDecoration: 'unset', color: 'unset' }}>
                    <ListItemButton
                        key={right.guid}
                        disabled={!hasViewRight}>
                        {
                            hasIcon ?
                                <ListItemIcon>
                                    {<img className={'h30 w30'} src={`${iconsLocation}/${lowercase(right.name).replace(/ /g, '_')}.svg`} />}
                                </ListItemIcon>
                                :
                                <div className={'h30 w30'} />
                        }
                        <ListItemText disableTypography className={`${pathname === right.url ? 'fw550' : ''} pl0`} inset primary={right.name} />
                    </ListItemButton>
                </Link>
            </CustomTooltip>
        );
    };

    const sections = useMemo<Array<JSX.Element>>(() => {
        if (isLoadingRights) return [<Loader key={'loader'}/>];

        if (!rightData) return [];

        const result = lodash.chain(rightData)
            .filter(x => x.isOnNavDrawer && x.isActive && !x.parentId)
            .filter(x => !!x.sectionOrder)
            .sortBy(x => x.sectionOrder)
            .map((x) => {
                const userRights = currentUser?.userRights;
                const hasParentViewRight = userRights?.some(userRight =>
                    userRight.isActive
                    && userRight.right.id === x.id
                    && userRight.rightGrantLevel === RightGrantsEnum.View) ?? false;

                return {
                    hasParentViewRight,
                    section: x,
                    children: lodash.chain(rightData)
                        .filter(y => y.isOnNavDrawer && y.isActive && y.parentId === x.id)
                        .filter(y => !!y.pageOrder)
                        .sortBy(y => y.pageOrder)
                        .map((child) => {
                            const hasChildViewRight = userRights?.some(userRight =>
                                userRight.isActive
                                && userRight.right.id === x.id
                                && userRight.rightGrantLevel === RightGrantsEnum.View) ?? false;

                            return {
                                hasChildViewRight,
                                child,
                            };
                        })
                        .orderBy(x => x.hasChildViewRight, 'desc')
                        .value(),
                };
            })
            .orderBy(x => x.hasParentViewRight, 'desc')
            .map((x) => {
                return x.section.isPage
                    ? renderListItem(x.section, true, path)
                    : renderListSection(x.section, x.children.map(x => x.child), currentUserRights, path);
            }).value();
        
        return result;
    }, [currentUser, rightData, path, isLoadingRights]);

    return (
        <div className={'fdc hfill'}>
            <NavBar
                env={EnvHelper.getEnvName()}
                version={VERSION.version}
                currentUser={currentUser}
                signOut={signOut}
                breadcrumbs={breadcrumbs}
                openDrawer={openDrawer}
                closeDrawer={closeDrawer}
                openUserSettings={openUserSettings}
                closeUserSettings={closeUserSettings}
                disabled={false}
                userSettingsDialog={userSettings}
                isLatestVersion={true}
            />
            <div className={'fdr flx1 oyh'}>
                <NavDrawer
                    env={EnvHelper.getEnvName()}
                    path={path}
                    currentUser={currentUser}
                    isOpen={isNavDrawerOpen}
                    isLoading={false}
                    sections={sections}
                    logOut={signOut}
                    closeDrawer={closeDrawer}
                    onHelpClick={handleFreshServiceTicketDialogOpen}
                />
                <Routes />
            </div>
            <FreshServiceTicketCreationDialog
                isOpen={isFreshServiceTicketDialogOpen}
                onDismiss={handleFreshServiceTicketDialogClose}
                description={freshTicketServiceDescription}
            />
        </div>
    );
};

export default NavFrame;