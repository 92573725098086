import React, { useEffect, useMemo } from 'react';
import SnackbarNotifier from './modules/root/SnackbarNotifier';
import { useLocation } from 'react-router';
import * as localStorageService from './service/localStorage/localStorageService';
import { useAppDispatch, useAppSelector } from './@types/redux';
import { initializeInterceptor } from './service/http/interceptor';
import AuthActions from './store/auth/actions';
import { IGoogleLoginError, IGoogleLoginSuccess, Loader, Login } from '@zz2/zz2-ui';
import { ThemeProvider } from '@mui/material';
import AuthThunk from './store/auth/thunk';
import navActions from './store/nav/actions';
import GeneralThunks from './store/general/thunk';
import NavFrame from './modules/root/NavFrame';
import defaultTheme from './style/materialTheme';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { QUERY_DATA_STALE_TIME } from './appConstants';
import { IUserToken } from './@types/model/right/userToken/userTokenModels';
import './style/app.scss';
import EnvHelper from './service/helper/envHelper';
import { VERSION } from './version';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
const App = () : React.ReactElement => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const isLoggingIn = useAppSelector<boolean>(x => x.auth.isLoggingIn);
    const isLoggingOut = useAppSelector<boolean>(x => x.auth.isLoggingOut);
    const session = useAppSelector<IUserToken | null>(x => x.auth.session);
    const showRecoveryScreen = location.pathname.includes('/reset-password');

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    const ReactQueryDevtoolsProduction = React.lazy(() =>
        import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
            (d) => ({
                default: d.ReactQueryDevtools,
            }),
        ),
    );

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        dispatch(AuthActions.setLoggingIn(true));
        localStorageService.onSessionChanged((user) => {

            if (user) {
                /* Logged In */
                initializeInterceptor(user.token, onUnauthenticated, onUnauthorized, onConnectionError);
                dispatch(AuthActions.setSession(user));
                dispatch(AuthActions.setToken(user.token));
                dispatch(AuthActions.setLoggedIn(true));
                dispatch(AuthActions.setLoggingIn(false));

            } else {
                /* Logged Out or Not yet logged in */
                initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
                dispatch(AuthActions.setLoggedIn(false));
                dispatch(AuthActions.setSession(null));
                dispatch(AuthActions.setLoggingIn(false));
            }
        });
    };


    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const onUnauthenticated = (error : any) : void => {
        initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: 'Unauthenticated.',
            ex: error,
        }));

        dispatch(AuthThunk.logout());
    };

    const onUnauthorized = (error : any) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: 'Insufficient rights.',
            ex: error,
        }));
    };

    const onConnectionError = () : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: 'Connection error.',
        }));
    };

    const manualLogIn = async (username : string, password : string) : Promise<void> => {
        dispatch(AuthThunk.manualLogIn({ username, password }));
    };

    const manualSignUp = async (employeeNumber : string, password : string) : Promise<void> => {
        dispatch(AuthThunk.signUp({ employeeNumber, password }));
    };


    const requestForgottenPassword = async () : Promise<void> => {
        //await dispatch(RightThunks.requestForgottenPassword({ email: recoveryEmailOrUsername }));
    };

    const onGoogleLogInSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        await dispatch(AuthThunk.googleLogIn({
            code: response.code,
        })).unwrap();

        navActions.navReplace('/home');
    };

    const onGoogleLogInFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: response.error ?? 'Login Error',
            ex: response,
        }));
        dispatch(AuthActions.setLoggingIn(false));
    };


    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const renderApp = useMemo(() => {
        if (isLoggingIn || isLoggingOut) {
            return <Loader />;
        }

        if (session) {
            return (
                <NavFrame />
            );
        }

        return showRecoveryScreen
            ? <div />
            //? <PasswordRecovery initialFormValues={passwordResetInitialFormValues()} /> 
            : <Login
                env={EnvHelper.getEnvName()}
                version={VERSION.version}
                isLoading={isLoggingIn}
                isLoggingIn={isLoggingIn}
                manualLogIn={manualLogIn}
                requestForgottenPassword={requestForgottenPassword}
                onGoogleSignInSuccess={onGoogleLogInSuccess}
                onGoogleSignInFailure={onGoogleLogInFailure}
                enableSignUp={true}
                manualSignUp={manualSignUp}
            />;

    }, [isLoggingIn, session, showRecoveryScreen, isLoggingOut]);



    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: { staleTime: QUERY_DATA_STALE_TIME },
            mutations: {}
        }
    });

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={defaultTheme}>
                {renderApp}
                <SnackbarNotifier />
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <React.Suspense fallback={null}>
                {
                    EnvHelper.isDevelopmentQa() &&
                    <ReactQueryDevtoolsProduction />
                }
            </React.Suspense>
        </QueryClientProvider>
    );
};

export default App;
