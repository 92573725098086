import React, { useEffect, useMemo } from 'react';
import { IOptionType, ProjectDialog } from '@zz2/zz2-ui';
import { useLocation } from 'react-router';
import { useAppSelector } from '../../@types/redux';
import { useGetUsers } from '../../hooks/query/user/userQueries';
import EnvHelper from '../../service/helper/envHelper';
import { IFreshServiceTicketFormValues } from '../../@types/model/freshService/freshServiceTicketFormValues';
import FreshServiceTicketModelHelper from '../../@types/model/freshService/freshServiceTicketModelHelper';
import { VERSION } from '../../version';
import { useHandleFreshServiceTicketUpsert } from '../../hooks/query/freshService/freshServiceQueries';
import FreshServiceTicketForm from './FreshServiceTicketForm';

interface IFreshServiceTicketEditDialogProps {
    isOpen : boolean;
    onDismiss : () => void;
    title ?: string;
    description ?: string;
}

const FreshServiceTicketCreationDialog = (props : IFreshServiceTicketEditDialogProps) : React.ReactElement => {
    const location = useLocation();
    const currentUser = useAppSelector(x => x.auth.session?.user);

    /*================================================================================================================
     *                                                  Queries
     * ==============================================================================================================*/

    const { isLoading: isLoadingUsers, data: users, refetch: refetchUsers } = useGetUsers(false);
    const { isLoading: isUpsertingFreshServiceTicket, mutate: upsertFreshServiceTicket, isSuccess: upsertSuccess } = useHandleFreshServiceTicketUpsert();

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        if (props.isOpen) {
            refetchUsers();
        }       
    }, [props.isOpen]);

    useEffect(() => {
        props.onDismiss();        
    }, [upsertSuccess]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const onSubmit = async (values : IFreshServiceTicketFormValues) : Promise<void> => {
        const upsert = FreshServiceTicketModelHelper.createUpsert(values);
        
        upsertFreshServiceTicket(upsert);
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const getInitialFormValues = () : IFreshServiceTicketFormValues => {
        const systemName = EnvHelper.getEnvName();

        return FreshServiceTicketModelHelper.createFormValues(
            systemName, 
            VERSION.version, 
            location.pathname, 
            currentUser?.name ?? '',
            currentUser?.email ?? '',
            props.description
        );
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const userOptions = useMemo<Array<IOptionType>>(() => {
        return users?.map(x => {
            return {
                label: x.name,
                value: x.id
            };

        }) ?? [];

    }, [users]);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <ProjectDialog
            title={'ZZ2 FRESH SERVICE SUPPORT TICKET'}
            isLoadingCircular={isUpsertingFreshServiceTicket || isLoadingUsers}
            isOpen={props.isOpen}
            fullWidth
            maxWidth={'sm'}
            onClose={props.onDismiss}>
            <FreshServiceTicketForm initialValues={getInitialFormValues()} onSubmit={onSubmit} onCancel={props.onDismiss} userOptions={userOptions} />
        </ProjectDialog>
    );
};


export default FreshServiceTicketCreationDialog;