import { OptionsObject } from 'notistack';
import { IAuthState } from '../store/auth/reducer';
import { IGeneralState } from '../store/general/reducer';
import { store, createRootReducer } from '../store';
import { AsyncThunk, AsyncThunkPayloadCreator, Draft, createAsyncThunk } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector as reduxSelector } from 'react-redux';
import { IFreshServiceState } from '../store/freshService/reducer';

export type DispatchCall<P> = (payload : P) => void;
export type DispatchCallEmpty = () => void;

export interface INotification {
    message : string | React.ReactNode;
    options ?: Draft<OptionsObject>;
}

export interface ISnackbarNotification extends INotification {
    key : number;
}

export type RootState = ReturnType<typeof createRootReducer>;

export interface IRootState {
    auth : IAuthState;
    general : IGeneralState;
    freshService : IFreshServiceState;
}

interface ThunkConfig {
    state : RootState;
}

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector : TypedUseSelectorHook<RootState> = reduxSelector;

export type payloadCreator<Returned, ThunkArg> = AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkConfig>;
export declare function AppAsyncThunk<Returned = void, ThunkArg = void>(typePrefix : string, payloadCreator : payloadCreator<Returned, ThunkArg>) : AsyncThunk<Returned, ThunkArg, ThunkConfig>;
export const createAppAsyncThunk : typeof AppAsyncThunk = (typePrefix : string, payloadCreator) => createAsyncThunk(typePrefix, payloadCreator);
