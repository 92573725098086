/*================================================================================================================
*                                                  Rights
* ==============================================================================================================*/

// users
export const GET_USER_KEY = 'user';
export const GET_USERS_KEY = 'users';
export const UPSERT_USERS_KEY = 'upsertUser';
export const LINK_USER_GOOGLE_ACCOUNT = 'linkUserGoogleAccount';
export const UNLINK_USER_EMAIL = 'unlinkUserEmail';
export const UPDATE_EMPLOYEE_CREDENTIALS = 'updateEmployeeCredentials';
export const RESET_EMPLOYEE_CREDENTIALS = 'resetEmployeeCredentials';
export const LOGOUT_USERS_KEY = 'logoutUsers';
export const LOGOUT_ALL_USERS_KEY = 'logoutAllUsers';
export const DELETE_USER_KEY = 'deleteUser';

// rights
export const GET_RIGHTS_KEY = 'rights';
export const UPSERT_RIGHT_KEY = 'upsertRight';
export const DELETE_RIGHT_KEY = 'deleteRight';

// roles
export const GET_ROLES_KEY = 'roles';
export const UPSERT_ROLE_KEY = 'upsertRole';
export const DELETE_ROLE_KEY = 'deleteRole';


/*================================================================================================================
*                                                  Master Data
* ==============================================================================================================*/

// Block
export const GET_BLOCKS_KEY = 'blocks';
export const UPSERT_BLOCK_KEY = 'upsertBlock';
export const DELETE_BLOCK_KEY = 'deleteBlock';

// Colour
export const GET_COLOURS_KEY = 'colours';
export const UPSERT_COLOUR_KEY = 'upsertColour';
export const DELETE_COLOUR_KEY = 'deleteColour';

// Commodity
export const GET_COMMODITIES_KEY = 'commodities';
export const UPSERT_COMMODITY_KEY = 'upsertCommodity';
export const DELETE_COMMODITY_KEY = 'deleteCommodity';

// Commodity Colour Relation
export const GET_COMMODITY_COLOUR_RELS_KEY = 'commodityColourRels';
export const UPSERT_COMMODITY_COLOUR_REL_KEY = 'upsertCommodityColourRel';
export const DELETE_COMMODITY_COLOUR_REL_KEY = 'deleteCommodityColourRel';
export const UPSERT_BULK_COMMODITY_COLOUR_RELS_KEY = 'upsertBulkCommodityColourRels';

// Commodity Size Relation
export const GET_COMMODITY_SIZE_RELS_KEY = 'commoditySizeRels';
export const UPSERT_COMMODITY_SIZE_REL_KEY = 'upsertCommoditySizeRel';
export const DELETE_COMMODITY_SIZE_REL_KEY = 'deleteCommoditySizeRel';
export const UPSERT_BULK_COMMODITY_SIZE_RELS_KEY = 'upsertBulkCommoditySizeRels';

// Config
export const GET_CONFIGS_KEY = 'configs';
export const UPSERT_CONFIG_KEY = 'upsertConfig';
export const DELETE_CONFIG_KEY = 'deleteConfig';

// Department
export const GET_DEPARTMENTS_KEY = 'departments';
export const UPSERT_DEPARTMENT_KEY = 'upsertDepartment';
export const DELETE_DEPARTMENT_KEY = 'deleteDepartment';

// Field
export const GET_FIELDS_KEY = 'fields';
export const UPSERT_FIELD_KEY = 'upsertField';
export const DELETE_FIELD_KEY = 'deleteField';

// Process
export const GET_PROCESSES_KEY = 'processes';
export const UPSERT_PROCESS_KEY = 'upsertProcess';
export const DELETE_PROCESS_KEY = 'deleteProcess';

// Production Unit
export const GET_PRODUCTION_UNITS_KEY = 'productionUnits';
export const UPSERT_PRODUCTION_UNIT_KEY = 'upsertProductionUnit';
export const DELETE_PRODUCTION_UNIT_KEY = 'deleteProductionUnit';

// Project
export const GET_PROJECTS_KEY = 'projects';
export const UPSERT_PROJECT_KEY = 'upsertProject';
export const DELETE_PROJECT_KEY = 'deleteProject';

// Qualifier
export const GET_QUALIFIERS_KEY = 'qualifiers';
export const UPSERT_QUALIFIER_KEY = 'upsertQualifier';
export const DELETE_QUALIFIER_KEY = 'deleteQualifier';

// Qualifier Type
export const GET_QUALIFIER_TYPES_KEY = 'qualifierTypes';
export const UPSERT_QUALIFIER_TYPE_KEY = 'upsertQualifierType';
export const DELETE_QUALIFIER_TYPE_KEY = 'deleteQualifierType';

// Site
export const GET_SITES_KEY = 'sites';
export const UPSERT_SITE_KEY = 'upsertSite';
export const DELETE_SITE_KEY = 'deleteSite';

// Size
export const GET_SIZES_KEY = 'sizes';
export const UPSERT_SIZE_KEY = 'upsertSize';
export const DELETE_SIZE_KEY = 'deleteSize';

// Variety
export const GET_VARIETIES_KEY = 'varieties';
export const UPSERT_VARIETY_KEY = 'upsertVariety';
export const DELETE_VARIETY_KEY = 'deleteVariety';


/*================================================================================================================
*                                             Dev Tools Master Data
* ==============================================================================================================*/

// Contact Us
export const GET_CONTACTS_KEY = 'contacts';
export const UPSERT_CONTACT_KEY = 'upsertContact';
export const DELETE_CONTACT_KEY = 'deleteContact';

// News
export const GET_NEWS_KEY = 'news';
export const UPSERT_NEWS_KEY = 'upsertNews';
export const DELETE_NEWS_KEY = 'deleteNews';

// FrequentlyAskedQuestion
export const GET_FREQUENTLY_ASKED_QUESTIONS_KEY = 'frequentlyAskedQuestion';
export const UPSERT_FREQUENTLY_ASKED_QUESTION_KEY = 'upsertfrequentlyAskedQuestion';
export const DELETE_FREQUENTLY_ASKED_QUESTION_KEY = 'deletefrequentlyAskedQuestion';

// Logging
export const GET_LOGS_KEY = 'logs';
export const GET_SYNC_STATUS = 'syncStatus';
export const SET_SYNC_STATUS = 'setSyncStatus';
export const TRIGGER_FULL_SYNC = 'fullSync';


/*================================================================================================================
*                                                    Fresh Service
* ==============================================================================================================*/

export const UPSERT_FRESH_SERVICE_TICKET_KEY = 'upsertFreshServiceTicket';

/*================================================================================================================
*                                                  Transactional
* ==============================================================================================================*/


// QCAction
export const GET_QC_ACTIONS_KEY = 'qcActions';
export const UPSERT_QC_ACTION_KEY = 'upsertQCAction';
export const DELETE_QC_ACTION_KEY = 'deleteQCAction';

// QCActionProcess
export const GET_QC_ACTION_PROCESSES_KEY = 'qcActionProcesses';
export const UPSERT_QC_ACTION_PROCESS_KEY = 'upsertQCActionProcess';
export const DELETE_QC_ACTION_PROCESS_KEY = 'deleteQCActionProcess';