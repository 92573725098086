import { IBaseUserRight } from '@zz2/zz2-ui';
import { RightGrantsEnum } from '../../@types/enum/rightGrantsEnum';

export default class UserHelper {
    public static userRelatedRights = (pathname : string, userRights ?: Array<IBaseUserRight>) => {
        const userHasRights = userRights?.filter(x => x.isActive && x.right.url !== null && x.right.url.toLowerCase() === pathname.toLowerCase()) ?? [];

        return {
            viewRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.View),
            addRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.Add),
            editRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.Edit),
            deleteRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.Delete),
            adminRight: userHasRights.some(x => x.rightGrantLevel === RightGrantsEnum.Admin)
        };
    }

    public static getRelatedRightToolTipMessage = (dataType : string, level : RightGrantsEnum) : string => {
        let rightLevel = '';
        switch (level) {
            case (RightGrantsEnum.Add):
                rightLevel = 'Add';
                break;

            case (RightGrantsEnum.Edit):
                rightLevel = 'Edit';
                break;

            case (RightGrantsEnum.Delete):
                rightLevel = 'Delete';
                break;

            default:
                break;
        }

        return `Right: ${dataType} with Level: ${rightLevel} is required to perform this action. \n\nClick to request permission.'`;
    }
}