import { IBaseUserRight, Loader, NoRightsScreen, Screen } from '@zz2/zz2-ui';
import React, { Suspense, lazy } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { RightGrantsEnum } from '../../@types/enum/rightGrantsEnum';
import { useAppSelector } from '../../@types/redux';

const PrivateRoute = (props : RouteProps) : JSX.Element => {
    const session = useAppSelector(x => x.auth.session);
    const { component, ...rest } = props;

    const hasMenuRight = (url : string) : boolean => {
        if (!session) return false;
        if (session.user.userRights.length < 1) return false;

        const userRights : Array<IBaseUserRight> = session.user.userRights;
        if (userRights.length < 1) {
            return false;
        } else {
            const urlSections = url.split('/');
            const firstPartition = urlSections.slice(0, -1).join('/');
            const filteredUserRights : Array<IBaseUserRight> = userRights.filter(x => !!x.isActive && x.right.url === (urlSections[urlSections.length - 1].includes('Id')
                ? firstPartition
                : url
            ));

            if (filteredUserRights.length > 0 && filteredUserRights.some(x => x.rightGrantLevel === RightGrantsEnum.View)) {
                return true;
            } else {
                return false;
            }
        }
    };

    const render = (routerProps : RouteComponentProps<any>) : React.ReactNode => {
        if (session) {
            if (!component) return;

            const Component = component;
            if (!hasMenuRight(routerProps.match.url)) {
                return <NoRightsScreen openSupportTicket={() => console.log('test')} />;
            }

            return <Component {...routerProps} />;
        }

        return <Redirect to={{ pathname: '/login', state: { from: routerProps.location } }} />;
    };

    return (<Route {...rest} render={render} />);
};

{/* Route Imports */ }
const Home = lazy(() => import('../home/Home'));

// {/* Master Data */ }
const MasterDataHome = React.lazy(() => import('../masterData/masterDataHome'));
const BlockManager = lazy(() => import('../masterData/block/BlockManager'));
const ColourManager = lazy(() => import('../masterData/colour/ColourManager'));
const CommodityManager = lazy(() => import('../masterData/commodity/CommodityManager'));
const ConfigManager = lazy(() => import('../masterData/config/ConfigManager'));
const DepartmentManager = lazy(() => import('../masterData/department/DepartmentManager'));
const FieldManager = lazy(() => import('../masterData/field/FieldManager'));
const ProcessManager = lazy(() => import('../masterData/process/ProcessManager'));
const ProjectManager = lazy(() => import('../masterData/project/ProjectManager'));
const ProductionUnitManager = lazy(() => import('../masterData/productionUnit/ProductionUnitManager'));
const QualifierManager = lazy(() => import('../masterData/qualifier/QualifierManager'));
const QualifierTypeManager = lazy(() => import('../masterData/qualifierType/QualifierTypeManager'));
const SiteManager = lazy(() => import('../masterData/site/SiteManager'));
const SizeManager = lazy(() => import('../masterData/size/SizeManager'));
const VarietyManager = lazy(() => import('../masterData/variety/VarietyManager'));

{/* Transaction */ }
const QCActionManager = lazy(() => import('../transaction/qcAction/QCActionManager'));

{/* Rights */ }
const UserManager = lazy(() => import('../right/userManagement/UserManager'));
const RightsManagement = lazy(() => import('../right/rightsManagement/RightsManager'));

// {/* Development Tools*/ }
const LogManager = React.lazy(() => import('../logging/LogManager'));
//TODO: add these
// 
// const ContactUsManager = React.lazy(() => import('../home/contactUs/ContactUsManager'));
// const NewsManager = React.lazy(() => import('../home/news/NewsManager'));
// const FrequentlyAskedQuestionManager = React.lazy(() => import('../home/frequentlyAskedQuestion/FrequentlyAskedQuestionManager'));


const Routes = () : React.ReactElement => {
    return (
        <Suspense fallback={<Screen><Loader /></Screen>}>
            <Switch>
                <Route
                    path={'/'} exact
                    render={() => <Redirect from={'/'} to={{ pathname: '/home' }} />}
                />

                <PrivateRoute exact path='/home' component={Home} />

                {/* MasterData */}
                <PrivateRoute exact path='/masterData' component={MasterDataHome} />
                <PrivateRoute exact path='/masterData/block' component={BlockManager} />
                <PrivateRoute exact path='/masterData/colour' component={ColourManager} />
                <PrivateRoute exact path='/masterData/commodity' component={CommodityManager} />
                {/* <PrivateRoute exact path='/masterData/commodityColourRel' component={CommodityColourRelManager} />
                <PrivateRoute exact path='/masterData/commoditySizeRel' component={CommoditySizeRelManager} /> */}
                <PrivateRoute exact path='/masterData/config' component={ConfigManager} />
                <PrivateRoute exact path='/masterData/department' component={DepartmentManager} />
                <PrivateRoute exact path='/masterData/field' component={FieldManager} />
                <PrivateRoute exact path='/masterData/process' component={ProcessManager} />
                <PrivateRoute exact path='/masterData/project' component={ProjectManager} />
                <PrivateRoute exact path='/masterData/productionUnit' component={ProductionUnitManager} />
                <PrivateRoute exact path='/masterData/qualifier' component={QualifierManager} />
                <PrivateRoute exact path='/masterData/qualifierType' component={QualifierTypeManager} />
                <PrivateRoute exact path='/masterData/site' component={SiteManager} />
                <PrivateRoute exact path='/masterData/size' component={SizeManager} />
                <PrivateRoute exact path='/masterData/variety' component={VarietyManager} />


                {/* Drop Off Allocation */}
                <PrivateRoute exact path='/qcSection/qcActionManager' component={QCActionManager} />

                {/* Rights */}
                <PrivateRoute exact path='/rights/userManagement' component={UserManager} />
                <PrivateRoute exact path='/rights/rightsManagement' component={RightsManagement} />

                {/* Development Tools*/}
                <PrivateRoute exact path='/development/tools' component={LogManager} />
                {/*  <PrivateRoute exact path='/development/contactUsManager' component={ContactUsManager} />
                <PrivateRoute exact path='/development/newsManager' component={NewsManager} />
                <PrivateRoute exact path='/development/faqManager' component={FrequentlyAskedQuestionManager} /> */}

            </Switch>
        </Suspense>
    );
};

export default Routes;