
import { RightGrantsEnum } from './@types/enum/rightGrantsEnum';
export const QUERY_DATA_STALE_TIME = 30 * 60 * 1000;

export const SNACKBAR_WIDTH = 400;

export const RIGHT_GRANT_LEVELS : Record<string, number> = {
    'View': RightGrantsEnum.View,
    'Add': RightGrantsEnum.Add,
    'Edit': RightGrantsEnum.Edit,
    'Delete': RightGrantsEnum.Delete,
    'Admin': RightGrantsEnum.Admin,
};

export const QUALIFIER_DATA_TYPES_OPTIONS : Array<{ label : string; value : number }> = [
    { label: 'YesNo', value: 1 },
    { label: 'Int', value: 2 },
    { label: 'Double', value: 3 },
    { label: 'String', value: 4 },
    { label: 'Photo', value: 5 },
    { label: 'Signature', value: 6 },
    { label: 'DateTime', value: 7 },
];